<script setup lang="ts">
import type { HeaderContainerProps } from './HeaderContainer.props'

defineProps<HeaderContainerProps>()
const { homeBrandPath } = useRouteHelper()
const { ts } = useI18n()

const { menuStatus } = useMenu()

const header = ref<HTMLElement>()

// Lock scroll if menu is open
const body = ref<HTMLElement>()
onMounted(() => {
  body.value = document?.body
})
const isLocked = useScrollLock(body)
watch(
  () => menuStatus.value,
  menu => {
    isLocked.value = menu?.isActive
  }
)

const isFederatedApp = computed(() => useRuntimeConfig().public.isFederated)

const titleBrandName = computed(() =>
  capitalizeFirstLetter(ts('accessibility.homepage'))
)
const { isShoppable } = await useIsShoppable()

const hpHasEditorialValuesTop = useState('hp_has_editorial_values_top')
const isHeaderHomeShort = ref(hpHasEditorialValuesTop.value)

const isExpandedTillFooter = ref(false)

const updateFooterHeight = () => {
  const footerElement = document.querySelector('footer')
  if (footerElement) {
    const height = footerElement.getBoundingClientRect().height
    document.documentElement.style.setProperty('--footer-height', `${height}px`)
  }
}

onMounted(() => {
  updateFooterHeight()

  window.addEventListener('resize', () => {
    updateFooterHeight()
  })

  window.addEventListener('header-home-reduce', () => {
    isExpandedTillFooter.value = false
    isHeaderHomeShort.value = true
  })
  window.addEventListener('header-home-expand', () => {
    isExpandedTillFooter.value = false
    isHeaderHomeShort.value = false
  })

  window.addEventListener('header-home-expand-to-footer', () => {
    isExpandedTillFooter.value = true
    isHeaderHomeShort.value = false
  })
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateFooterHeight)
})
</script>

<template>
  <div>
    <header
      ref="header"
      role="banner"
      class="bg-neutral-white text-neutral-black transition-colors duration-1000"
      :class="[
        'z-[49] w-full',
        isHeaderHomeShort ? 'bg-transparent' : 'bg-neutral-white',
        isHeaderHomeShort && !menuStatus?.isActive
          ? 'landscape:text-neutral-white hover:landscape:text-neutral-black hover:landscape:bg-primitives-white'
          : '',
        {
          '!z-50': menuStatus?.isActive,
        },
      ]"
    >
      <div
        class="lv-header relative z-[49] w-full bg-white transition duration-500 ease-out"
      >
        <div
          class="lv-header__container lv-transition relative mx-4 flex justify-between py-3 md:mx-6 lg:mx-12 lg:py-6 xl:mx-16"
        >
          <div class="cta-left hidden lg:block">
            <HeaderActionContainer class="items-center gap-7">
              <template #default="{ menuStatus, toggleMenu, openSearch }">
                <HeaderActionMenu
                  data-testid="open-menu-desktop"
                  :is-active="menuStatus.isActive"
                  @click-handler="toggleMenu"
                />
                <HeaderActionSearch
                  v-if="!isFederatedApp && !menuStatus.isActive"
                  @click-handler="openSearch"
                />
              </template>
            </HeaderActionContainer>
          </div>

          <!-- DESKTOP -->
          <div
            :class="[
              'logo-container-homepage flex items-center',
              {
                'logo-container-homepage--expand-to-footer':
                  isExpandedTillFooter,
                'logo-container-homepage--short': isHeaderHomeShort,
                'page-has-confirm-country': pageHasConfirmCountry,
              },
            ]"
          >
            <NuxtLink
              :to="homeBrandPath"
              class="h-full"
              :aria-label="`${$ts('accessibility.goTo')} ${titleBrandName}`"
            >
              <component
                :is="menuLogoMap['global']"
                class="m-auto w-auto transition-[height] duration-500 ease-out"
                aria-hidden="true"
              />
            </NuxtLink>
          </div>

          <div
            class="cta-right lv-header__utility relative hidden h-full lg:block"
          >
            <HeaderActionContainer class="items-center justify-end gap-7">
              <template
                v-if="isShoppable"
                #default="{
                  isShopActive,
                  isUserLogged,
                  openSupport,
                  toggleMyAccount,
                }"
              >
                <HeaderActionSupport @click-handler="openSupport" />
                <HeaderActionAccount
                  :is-user-logged="isUserLogged"
                  @click-handler="toggleMyAccount"
                />
                <!-- TODO: (new heder) move out the logic from <HeaderActionCart /> -->
                <HeaderActionCart v-if="isShopActive" />
              </template>
            </HeaderActionContainer>
          </div>
        </div>
        <HeaderMenuNav :lists="menuLists" />
      </div>
    </header>
  </div>
</template>
